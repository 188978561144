<template>
  <div class="designer-setting-page">
    <router-view />
    <c-menu></c-menu>
  </div>
</template>

<script>
import CMenu from "@/components/CMenu/index";
export default {
  components: {
    CMenu,
  },
};
</script>
